<template>
    <div>
        <UPButton class="customBtn"
                  @click="btnClick"
                  :scope="scope"
                  :style="{width:'10rem',height:'10rem',backgroundColor:'red'}">
            授权button
        </UPButton>
    </div>
</template>

<script>
  export default {
    name: 'Bindysf',
    data () {
      return {
        scope: 'scope.mobile'
      }
    },
    methods: {
      btnClick: function (event, err, code) {
        alert('6')
        console.log(event)
        console.log(err)
        console.log(code)
      }
    }
  }
</script>

<style scoped>

</style>
